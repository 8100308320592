import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
import Cookie from "js-cookie";
import { canNavigate } from "@/libs/acl/routeProtection";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    // *===============================================---*
    // *--------- PUBLIC ---------------------------------*
    // *===============================================---*
    {
      path: "/login/:locale?",
      name: "login",
      component: () => import("@/views/authentication/Login.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Public",
      },
    },
    {
      path: "/ref/:upline/:locale?",
      name: "register",
      component: () => import("@/views/authentication/Register.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Public",
      },
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import("@/views/authentication/ForgotPassword.vue"),
      meta: {
        layout: "full",
        resource: "Public",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/reset-password/:token",
      name: "reset-password",
      component: () => import("@/views/authentication/ResetPassword.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Public",
      },
    },
    {
      path: "/new-password/:token/:email",
      name: "new-password",
      component: () => import("@/views/authentication/NewPassword.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Public",
      },
    },
    {
      path: "/oauth/callback/token/:token",
      name: "callback-oauth",
      component: () => import("@/views/authentication/CallbackOAuth.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Public",
      },
    },
    {
      path: "/verify-email/:id/:token",
      name: "verify-email",
      component: () => import("@/views/authentication/VerifyEmail.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Public",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Public",
      },
    },
    {
      path: "/not-authorized",
      name: "not-authorized",
      // ! Update import path
      component: () => import("@/views/error/NotAuthorized.vue"),
      meta: {
        layout: "full",
        action: "read",
        resource: "Public",
      },
    },

    // *===============================================---*
    // *--------- CUSTOMER -------------------------------*
    // *===============================================---*
    {
      path: "/dashboard-analytics",
      name: "dashboard-analytics",
      component: () => import("@/views/dashboard/trading-robot/Analytics.vue"),
      meta: {
        // pageTitle: "Robot trader",
        // breadcrumb: [
        //   {
        //     text: "Robot trader",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/",
      name: "dashboard-my-results",
      component: () => import("@/views/dashboard/my-results/MyResults.vue"),
      meta: {
        // breadcrumb: [
        //   {
        //     text: "Robot trader",
        //     to: "/",
        //   },
        //   {
        //     text: "My results",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/licences",
      name: "licenses",
      component: () => import("@/views/plans/Plans.vue"),
      meta: {
        // breadcrumb: [
        //   {
        //     text: "Robot trader",
        //     to: "/",
        //   },
        //   {
        //     text: "My results",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/robots-in-operation",
      name: "in-operation",
      component: () => import("@/views/dashboard/my-results/RobotsInOperation.vue"),
      meta: {
        // breadcrumb: [
        //   {
        //     text: "Robot trader",
        //     to: "/",
        //   },
        //   {
        //     text: "My results",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/affiliate-network/binary-tree",
      name: "affiliate-network-binary-tree",
      component: () => import("@/views/affiliate-network/BinaryTree.vue"),
      meta: {
        // pageTitle: "Binary tree",
        // breadcrumb: [
        //   {
        //     text: "Affiliate network",
        //     to: "/affiliate-network/dashboard",
        //   },
        //   {
        //     text: "Binary tree",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/affiliate-network/direct-references",
      name: "affiliate-network-direct-references",
      component: () => import("@/views/affiliate-network/ReferenceList.vue"),
      meta: {
        // pageTitle: "Direct References",
        // breadcrumb: [
        //   {
        //     text: "Affiliate network",
        //     to: "/affiliate-network/dashboard",
        //   },
        //   {
        //     text: "Direct References",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/affiliate-network/dashboard",
      name: "affiliate-network-dashboard",
      component: () =>
        import("@/views/affiliate-network/dashboard-affiliates/Dashboard.vue"),
      meta: {
        // pageTitle: "Affiliate dashboard",
        // breadcrumb: [
        //   {
        //     text: "Affiliate network",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/affiliate-network/bonus-report",
      name: "affiliate-bonus-report",
      component: () =>
        import("@/views/affiliate-network/dashboard-affiliates/BonusHistorical.vue"),
      meta: {
        // pageTitle: "Affiliate dashboard",
        // breadcrumb: [
        //   {
        //     text: "Affiliate network",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/wallet/my-deposits",
      name: "wallet-my-deposits",
      component: () => import("@/views/wallet/DepositsList.vue"),
      meta: {
        // pageTitle: "Deposits Crypto",
        // breadcrumb: [
        //   {
        //     text: "My money",
        //     active: true,
        //   },
        //   {
        //     text: "Deposits Crypto",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/wallet/my-deposits-pix",
      name: "wallet-my-deposits-pix",
      component: () => import("@/views/wallet/DepositsListPix.vue"),
      meta: {
        // pageTitle: "Deposits Pix",
        // breadcrumb: [
        //   {
        //     text: "My money",
        //     active: true,
        //   },
        //   {
        //     text: "Deposits Pix",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/wallet/my-withdrawals",
      name: "wallet-my-withdrawals",
      component: () => import("@/views/wallet/WithdrawalsList.vue"),
      meta: {
        // pageTitle: "Withdrawals",
        // breadcrumb: [
        //   {
        //     text: "My money",
        //     active: true,
        //   },
        //   {
        //     text: "Withdrawals",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/wallet/my-withdrawals-pix",
      name: "wallet-my-withdrawals-pix",
      component: () => import("@/views/wallet/WithdrawalsListPIX.vue"),
      meta: {
        // pageTitle: "Withdrawals PIX",
        // breadcrumb: [
        //   {
        //     text: "My money",
        //     active: true,
        //   },
        //   {
        //     text: "Withdrawals PIX",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/wallet/transfers",
      name: "wallet-transfers",
      component: () => import("@/views/wallet/TransfersList.vue"),
      meta: {
        // pageTitle: "Transfers",
        // breadcrumb: [
        //   {
        //     text: "My money",
        //     active: true,
        //   },
        //   {
        //     text: "Transfers",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/marketing/academy",
      name: "marketing-academy",
      component: () => import("@/views/marketing/Academy.vue"),
      meta: {
        // pageTitle: "Transfers",
        // breadcrumb: [
        //   {
        //     text: "My money",
        //     active: true,
        //   },
        //   {
        //     text: "Transfers",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/marketing/share",
      name: "marketing-share",
      component: () => import("@/views/marketing/Share.vue"),
      meta: {
        // pageTitle: "Transfers",
        // breadcrumb: [
        //   {
        //     text: "My money",
        //     active: true,
        //   },
        //   {
        //     text: "Transfers",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/marketing/support-material",
      name: "marketing-support-material",
      component: () => import("@/views/marketing/SupportMaterial.vue"),
      meta: {
        // pageTitle: "Transfers",
        // breadcrumb: [
        //   {
        //     text: "My money",
        //     active: true,
        //   },
        //   {
        //     text: "Transfers",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/account-setting",
      name: "account-setting",
      component: () => import("@/views/account-setting/AccountSetting.vue"),
      meta: {
        // pageTitle: "Account Settings",
        // breadcrumb: [
        //   {
        //     text: "Account Settings",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/support",
      name: "support",
      component: () => import("@/views/support/Support.vue"),
      meta: {
        // pageTitle: "Support",
        // breadcrumb: [
        //   {
        //     text: "Support",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/support-whatsapp",
      name: "support-whatsapp",
      beforeEnter() {
        window.open("https://wa.me/message/6QSVCAM7BNWCM1", "_blank");
      },
      meta: {
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/support/new-ticket",
      name: "support-new-ticket",
      component: () => import("@/views/support/NewTicket.vue"),
      meta: {
        // pageTitle: "Support",
        // breadcrumb: [
        //   {
        //     text: "Support",
        //     to: "/support",
        //   },
        //   {
        //     text: "New ticket",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },
    {
      path: "/support/ticket/:idTicket",
      name: "support-ticket",
      component: () => import("@/views/support/TicketResponse.vue"),
      meta: {
        // pageTitle: "Support",
        // breadcrumb: [
        //   {
        //     text: "Support",
        //     to: "/support",
        //   },
        //   {
        //     text: "Ticket",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },

    // *===============================================---*
    // *--------- DASHBOARD ------------------------------*
    // ================================================---*

    {
      path: "/trading-bot",
      name: "/dashboard/analytics/trading-bot",
      component: () => import("@/views/account-setting/AccountSetting.vue"),
      meta: {
        pageTitle: "Trading Bot",
        // breadcrumb: [
        //   {
        //     text: "dashboards",
        //     active: true,
        //   },
        //   {
        //     text: "trading-bot",
        //     active: true,
        //   },
        // ],
        action: "read",
        resource: "registered-user",
      },
    },

    // *===============================================---*
    // *--------- ADMINISTRATOR ---- ---------------------*
    // *===============================================---*
    {
      path: "/administrator/dashboard",
      name: "administrator-dashboard",
      component: () => import("@/views/administrator/Dashboard.vue"),
      meta: {
        pageTitle: "General view",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "General view",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_dashboard",
      },
    },
    {
      path: "/administrator/users/list",
      name: "administrator-users-list",
      component: () =>
        import("@/views/administrator/user/users-list/UsersList.vue"),
      meta: {
        pageTitle: "Users list",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Users list",
            active: true,
          },
        ],
        action: "read",
        resource: "customer-service_list-users",
      },
    },
    {
      path: "/administrator/users/view/:id",
      name: "administrator-users-view",
      component: () =>
        import("@/views/administrator/user/users-view/UsersView.vue"),
      meta: {
        pageTitle: "Users view",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Users list",
            to: "/administrator/users/list",
          },
          {
            text: "Users view",
            active: true,
          },
        ],
        action: "read",
        resource: "customer-service_user-view",
      },
    },
    {
      path: "/administrator/users/edit/:id",
      name: "administrator-users-edit",
      component: () =>
        import("@/views/administrator/user/users-edit/UsersEdit.vue"),
      meta: {
        pageTitle: "Users edit",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Users list",
            to: "/administrator/users/list",
          },
          {
            text: "Users view",
            to: "/administrator/users/view/:id",
          },
          {
            text: "Users edit",
            active: true,
          },
        ],
        action: "read",
        resource: "customer-service_user-edit",
      },
    },
    {
      path: "/administrator/robot-config",
      name: "administrator-robot-config",
      component: () => import("@/views/administrator/RobotConfig.vue"),
      meta: {
        pageTitle: "Robot config",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Robot config",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_dashboard",
      },
    },
    {
      path: "/administrator/set-permissions",
      name: "administrator-set-permissions",
      component: () => import("@/views/administrator/SetPermissions.vue"),
      meta: {
        pageTitle: "Set permissions",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Set permissions",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_permissions",
      },
    },
    {
      path: "/deposits-pix-approve-reject",
      name: "deposits-pix-approve-reject",
      component: () =>
        import("@/views/administrator/DepositsListPixApproveReject.vue"),
      meta: {
        pageTitle: "Approve/reject PIX deposits",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Approve/reject PIX deposits",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_permissions",
      },
    },
    {
      path: "/crypto-withdrawal-list",
      name: "crypto-withdrawal-list",
      component: () => import("@/views/administrator/CryptoWithdrawalList.vue"),
      meta: {
        pageTitle: "Crypto Withdrawal List",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Crypto Withdrawal List",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_permissions",
      },
    },
    {
      path: "/pix-withdrawal-list",
      name: "pix-withdrawal-list",
      component: () => import("@/views/administrator/PIXWithdrawalList.vue"),
      meta: {
        pageTitle: "PIX Withdrawal List",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "PIX Withdrawal List",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_permissions",
      },
    },
    {
      path: "/crypto-deposits-list2",
      name: "crypto-deposits-list2",
      component: () => import("@/views/administrator/CryptoDepositsList2.vue"),
      meta: {
        pageTitle: "Depósitos por USDT",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Depósitos por USDT",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_permissions",
      },
    },
    {
      path: "/crypto-deposits-list",
      name: "crypto-deposits-list",
      component: () => import("@/views/administrator/CryptoDepositsList.vue"),
      meta: {
        pageTitle: "Relatório de vendas",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Relatório de vendas",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_permissions",
      },
    },
    {
      path: "/transfers-reports",
      name: "transfers-reports",
      component: () => import("@/views/administrator/TransfersReports.vue"),
      meta: {
        pageTitle: "Transfers Reports",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Transfers Reports",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_permissions",
      },
    },
    {
      path: "/add-remove-balance",
      name: "add-remove-balance",
      component: () => import("@/views/administrator/AddRemoveBalance.vue"),
      meta: {
        pageTitle: "Sponsorship",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Sponsorship",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_permissions",
      },
    },
    {
      path: "/graduations",
      name: "graduations",
      component: () => import("@/views/administrator/Graduations.vue"),
      meta: {
        pageTitle: "Graduations",
        breadcrumb: [
          {
            text: "Administrator",
            active: true,
          },
          {
            text: "Graduations",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_permissions",
      },
    },
    {
      path: "/bonus-report",
      name: "admin-bonus-report",
      component: () => import("@/views/administrator/bonus-report/BonusReport.vue"),
      meta: {
        pageTitle: "Relatório de bônus",
        breadcrumb: [
          {
            text: "Relatório de bônus",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_permissions",
      },
    },
    {
      path: "/binary-report",
      name: "admin-binary-report",
      component: () => import("@/views/administrator/bonus-report/BinaryPointsReport.vue"),
      meta: {
        pageTitle: "Relatório de bônus binário",
        breadcrumb: [
          {
            text: "Relatório de bônus binário",
            active: true,
          },
        ],
        action: "read",
        resource: "admin_permissions",
      },
    },

    // *===============================================---*
    // *--------- CUSTOMER SERVICE -----------------------*
    // *===============================================---*
    {
      path: "/customer-service/tickets-list",
      name: "customer-service-tickets-list",
      component: () => import("@/views/customer-service/TicketsList.vue"),
      meta: {
        pageTitle: "Tickets list",
        breadcrumb: [
          {
            text: "Customer service",
            active: true,
          },
          {
            text: "Tickets list",
            active: true,
          },
        ],
        action: "read",
        resource: "customer-service_list-tickets",
      },
    },
    {
      path: "/customer-service/ticket-response/:idTicket",
      name: "customer-service-ticket-response",
      component: () => import("@/views/customer-service/TicketResponse.vue"),
      meta: {
        pageTitle: "Reply to support ticket",
        breadcrumb: [
          {
            text: "Customer service",
            to: "/customer-service/tickets-list",
          },
          {
            text: "Reply to support ticket",
            active: true,
          },
        ],
        action: "read",
        resource: "customer-service_response-ticket",
      },
    },

    // *===============================================---*
    // *--------- EXCHANGE ---- ---------------------*
    // *===============================================---*
    {
      path: "/exchange/negotiate-igc",
      name: "exchange-igc-brl",
      component: () => import("@/views/exchange/NegotiateIGC.vue"),
      meta: {
        pageTitle: "Negotiate IGC",
        breadcrumb: [
          {
            text: "Exchange",
            active: true,
          },
          {
            text: "Negotiate IGC",
            active: true,
          },
        ],
        action: "read",
        resource: "registered-user",
      },
    },

    // *===============================================---*
    // *--------- ERROR ---- ----------------------------*
    // *===============================================---*
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// Router Before Each hook for route protection
router.beforeEach(async (to, _, next) => {
  let isLoggedIn = Cookie.get("newxcoinsToken");

  const userData = store.state.authentication.user;

  if (isLoggedIn && userData.length == 0) {
    await store.dispatch("recoverUserData").catch((err) => {
      Cookie.remove("newxcoinsToken");
      isLoggedIn = false;
    });
  }

  setTimeout(() => {
    if (!canNavigate(to, store.state.authentication.user)) {
      // Redirect to login if not logged in
      if (!isLoggedIn) return next({ name: "login" });

      // If logged in => not authorized
      return next({ name: "not-authorized" });
    }
  }, 100);

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next("/");
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
