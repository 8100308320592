<template>
  <div class="demo-spacing-0">
    <b-alert
      v-height-fade.appear
      :show="dismissCountDown"
      dismissible
      class="mb-0"
      :variant="color"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <div class="alert-body">
        <span>{{ text }}</span>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { BAlert, BButton } from "bootstrap-vue";
import { heightFade } from "@core/directives/animations";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BAlert,
    BButton,
  },
  directives: {
    "height-fade": heightFade,
    Ripple,
  },
  props: {
    color: {
      type: String,
      default: "warning",
    },
    text: String,
    showAlert: Boolean,
  },
  data() {
    return {
      dismissSecs: 4,
      dismissCountDown: 0,
    };
  },
  watch: {
    showAlert: function (newVal) {
      if (newVal) {
        this.openAlert();
      }
      this.$emit("changeVariableAlert", false);
    },
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    openAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>