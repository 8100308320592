import api from "@/libs/axios";

const affiliates = {
    state: {
        referencesList: [],
    },
    getters: {
        returnReferencesList: state => state.referencesList,
    },
    actions: {
        fetchBonusHistorical({ commit }, params) {
            return new Promise(async (resolve, reject) => {
                const { startDate, endDate, bonusType, limit } = params || {};
                let query = '';
                if (startDate) query += `&startDate=${startDate}`;
                if (endDate) query += `&endDate=${endDate}`;
                if (bonusType) query += `&bonusType=${bonusType}`;
                if (limit) query += `&limit=${limit}`
                await api.get(`/affiliates/bonus-log?${query}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },
        myUpline: ({ commit }) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/affiliates/my-upline`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        }, 
        diagramBinaryTree: ({ commit }, username) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/affiliates/diagram-binary-tree/${username}`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },
        saveSelectedLeg: ({ commit }, selectedLeg) => {
            return new Promise(async (resolve, reject) => {
                await api.patch(`/affiliates/binary-tree/save-selected-leg`, { selectedLeg: selectedLeg }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },
        userReferencesList: ({ commit }, params) => {
            return new Promise(async (resolve, reject) => {
                let outhersParams = ''
                if (params.search != '' && params.search != null) {
                    outhersParams = outhersParams + `&search=${params.search}`
                }
                if (params.status != '' && params.status != null) {
                    outhersParams = outhersParams + `&status=${params.status}`
                }
                if (params.function != '' && params.function != null) {
                    outhersParams = outhersParams + `&function=${params.function}`
                }
                if (params.sortby == null) {
                    params.sortby = 'created_at'
                }
                if (params.sortdirection == null) {
                    params.sortdirection = 'desc'
                }
                if (params.page == null) {
                    params.page = '1'
                }
                if (params.limit == null) {
                    params.limit = '50'
                }
                if (params.affiliateId != '' && params.affiliateId != null) {
                    outhersParams = outhersParams + `&affiliateId=${params.affiliateId}`
                }
                await api.get(`/affiliates/reference-list?page=${params.page}&limit=${params.limit}&sortby=${params.sortby}&sortdirection=${params.sortdirection}${outhersParams}`).then(response => {
                    commit("addUserReferencesList", response.data);
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data.message)
                })
            });
        },
        affiliatesDashboard: ({ commit }, selectedLeg) => {
            return new Promise(async (resolve, reject) => {
                await api.get(`/affiliates/dashboard`).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },
    },
    mutations: {
        addUserReferencesList: (state, list) => {
            state.referencesList = list
        },
    },
}

export default affiliates;